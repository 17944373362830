/// <reference lib="webworker" />
/* eslint-disable no-restricted-globals */

// This service worker can be customized!
// See https://developers.google.com/web/tools/workbox/modules
// for the list of available Workbox modules, or add any other
// code you'd like.
// You can also remove this file if you'd prefer not to use a
// service worker, and the Workbox build step will be skipped.

import { url } from 'inspector';
import { response } from 'msw';
import { clientsClaim } from 'workbox-core';
import { ExpirationPlugin } from 'workbox-expiration';
import { precacheAndRoute, createHandlerBoundToURL } from 'workbox-precaching';
import { registerRoute } from 'workbox-routing';
import { CacheFirst, StaleWhileRevalidate } from 'workbox-strategies';
import { TRIPGO_API_CACHE_NAME } from './cache/CacheUtil';

declare const self: ServiceWorkerGlobalScope;

clientsClaim();

// Precache all of the assets generated by your build process.
// Their URLs are injected into the manifest variable below.
// This variable must be present somewhere in your service worker file,
// even if you decide not to use precaching. See https://cra.link/PWA
// precacheAndRoute(self.__WB_MANIFEST);

// Set up App Shell-style routing, so that all navigation requests
// are fulfilled with your index.html shell. Learn more at
// https://developers.google.com/web/fundamentals/architecture/app-shell
const fileExtensionRegexp = new RegExp('/[^/?]+\\.[^/]+$');
// registerRoute(
//   // Return false to exempt requests from being fulfilled by index.html.
//   ({ request, url }: { request: Request; url: URL }) => {
//     console.log("In service worker!");
//     console.log(request);
//     console.log(url);
//     // If this isn't a navigation, skip.
//     if (request.mode !== 'navigate') {
//       return false;
//     }

//     // If this is a URL that starts with /_, skip.
//     if (url.pathname.startsWith('/_')) {
//       return false;
//     }

//     // If this looks like a URL for a resource, because it contains
//     // a file extension, skip.
//     if (url.pathname.match(fileExtensionRegexp)) {
//       return false;
//     }

//     // Return true to signal that we want to use the handler.
//     return true;
//   },
//   createHandlerBoundToURL(process.env.PUBLIC_URL + '/index.html')
// );
const version = "1.3";
console.log("version:", version);

async function convertToGet(request: Request): Promise<Request> {
  const requestCopy = request.clone();
  const xTripGoKey = requestCopy.headers.get("X-TripGo-Key");
  const newUrl = `${requestCopy.url}?body=${JSON.stringify(await requestCopy.json())}&xTripGoKey=${xTripGoKey}`;
  return new Request(newUrl, {
    ...requestCopy,
    method: 'GET',
    body: undefined
  });
}

// An example runtime caching route for requests that aren't handled by the
// precache, in this case same-origin .png requests like those from in public/
registerRoute(
  // Add in any other file extensions or routing criteria as needed.
  ({ url }) => url.origin === self.location.origin && url.pathname.endsWith('.png'),
  // Customize this strategy as needed, e.g., by changing to CacheFirst.
  new StaleWhileRevalidate({
    cacheName: 'images',
    plugins: [
      // Ensure that once this runtime cache reaches a maximum size the
      // least-recently used images are removed.
      new ExpirationPlugin({ maxEntries: 50 }),
    ],
  })
);

// registerRoute(({ request }) => {  
//   console.log(request);  
//   return request.url.includes("api.tripgo.com");
// }, new CacheFirst(), 'POST);

// registerRoute(({ request }) => {
//   console.log(request);
//   return request.url.includes("api.tripgo.com");
// },
//   async (options) => {
//     console.log(options);
//     const { request } = options;
//     console.log(request.body);
//     return (new CacheFirst()).handle(options);
//   },
//   'POST');

registerRoute(({ request }) => {
  // console.log(request);
  return request.url.includes("api.tripgo.com/v1");
},
  async (options) => {
    // console.log("options");
    // console.log(options);
    const { request } = options;
    // console.log("request.body");
    // console.log(await request.json());
    // const requestForFetch = new Request(request);
    const cache = await caches.open(TRIPGO_API_CACHE_NAME);
    const postAsGetRequest = await convertToGet(request);
    const response = await cache.match(postAsGetRequest);
    if (response !== undefined) {
      return response;
    } else {
      return fetch(request)
        .then((response) => {
          // response may be used only once
          // we need to save clone to put one copy in cache
          // and serve second one
          let responseClone = response.clone();
          cache.put(postAsGetRequest, responseClone);
          return response;
        });
    }


    return (new CacheFirst()).handle(options);
  },
  'POST');

// This allows the web app to trigger skipWaiting via
// registration.waiting.postMessage({type: 'SKIP_WAITING'})
self.addEventListener('message', (event) => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    self.skipWaiting();
  }
});

// Any other custom service worker logic can go here.
